import { render, staticRenderFns } from "./eblock_info1.vue?vue&type=template&id=3bb744ab&"
import script from "./eblock_info1.vue?vue&type=script&lang=js&"
export * from "./eblock_info1.vue?vue&type=script&lang=js&"
import style0 from "./eblock_info1.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports