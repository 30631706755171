<template>
	<div class="body body--eblock">
		<div class="eblock_page">
			<div class="eblock_title">
				系统运行参数
			</div>
			<div class="eblock_body">
				<a-row type="flex" justify="space-around" align="middle" :gutter="[0,0]" style="background: #23232D;">
					<a-col :span="4">
				       <div class="eblock_data_item" id="power-chart">
					  	</div>
			      	</a-col>
			      	<a-col :span="5">
				       <div class="eblock_data_item">
					    	<div style="height: 246px;">
					      		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style="display: none;">
							        <symbol id="wave">
							            <path d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z"></path>
							            <path d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z"></path>
							            <path d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z"></path>
							            <path d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z"></path>
							        </symbol>
							    </svg>
							    <div class="box">
							        <div class="percent">
							        	<div class="percentA">SOC：</div>
							            <div class="percentNum" id="count">{{0}}</div>
							            <div class="percentB">%</div>
							        </div>
							        <div id="water" class="water">
							            <svg viewBox="0 0 560 20" class="water_wave water_wave_back">
							                <use xlink:href="#wave"></use>
							            </svg>
							            <svg viewBox="0 0 560 20" class="water_wave water_wave_front">
							                <use xlink:href="#wave"></use>
							            </svg>
							        </div>
							    </div>
		      				</div>
					  	</div>
			      	</a-col>
			      	<a-col :span="5">
			     		<div class="eblock_data_name eblock_data_name_state">
			     			工作状态：
			     		</div>
			     		<div class="eblock_data_value eblock_data_value_state" id="status" style="color: #7C868D">
			     			{{eblockstate}}
			     		</div>
			     		<div class="eblock_data_name">
			     			SOC：
			     		</div>
			     		<div class="eblock_data_value">
			     			{{soc}}
			     		</div>
			     		<div class="eblock_data_name">
			     			有机气体：
			     		</div>
			     		<div class="eblock_data_value">
			     			{{innerVO2Concentration}}
			     		</div>
			     		<div class="eblock_data_name">
			     			环境温度：
			     		</div>
			     		<div class="eblock_data_value">
			     			{{outterTemperature}}
			     		</div>
			     		<div class="eblock_data_name">
			     			环境湿度：
			     		</div>
			     		<div class="eblock_data_value">
							{{outterHumidity}}
			     		</div>
			      	</a-col>
			      	<a-col :span="5">
			        	<div class="eblock_data_name">
			     			气溶胶：
			     		</div>
			     		<div class="eblock_data_value">
			     			<div class="status" id="aerosolInputDetectionStatus"></div>
			     		</div>
			     		<div class="eblock_data_name">
			     			下水位浸：
			     		</div>
			     		<div class="eblock_data_value">
			     			<div class="status" id="downFlood"></div>
			     		</div>
			     		<div class="eblock_data_name">
			     			上水位浸：
			     		</div>
			     		<div class="eblock_data_value">
			     			<div class="status" id="upperFlood"></div>
			     		</div>
			     		<div class="eblock_data_name">
			     			左门禁：
			     		</div>
			     		<div class="eblock_data_value">
			     			<div class="status" id="accessControlLeft"></div>
			     		</div>
			     		<div class="eblock_data_name">
			     			右门禁：
			     		</div>
			     		<div class="eblock_data_value">
			     			<div class="status" id="accessControlRight"></div>
			     		</div>
			      	</a-col>
			      	<a-col :span="4">
			     		<div class="eblock_data_name">
			     			空调开关：
			     		</div>
			     		<div class="eblock_data_value">
			     			<div class="status" id="airConditionerSwitchStatus"></div>
			     		</div>
			     		<div class="eblock_data_name">
			     			加热开关：
			     		</div>
			     		<div class="eblock_data_value">
			     			<div class="status" id="airConditioningHeatingStatus"></div>
			     		</div>
			     		<div class="eblock_data_name">
			     			制冷开关：
			     		</div>
			     		<div class="eblock_data_value">
			     			<div class="status" id="airConditioningCoolingStatus"></div>
			     		</div>
			     		<div class="eblock_data_name">
			     			内风机：
			     		</div>
			     		<div class="eblock_data_value">
			     			<div class="status" id="innerAirConditionerFanStatus"></div>
			     		</div>
			     		<div class="eblock_data_name">
			     			外风机：
			     		</div>
			     		<div class="eblock_data_value">
			     			<div class="status" id="outsideAirConditionerFanStatus"></div>
			     		</div>
			      	</a-col>
				</a-row>
			</div>
		</div>
		<div class="eblock_page" style="height: 710px;">
			<div>
				<a-row type="flex" justify="space-around" align="middle" :gutter="[0,0]">
					<a-col :span="4">
						<div class="eblock_page eblock_page_sub">
							<div class="eblock_title">
								电压统计
							</div>
							<div class="eblock_body eblock_body_sub">
								<a-row type="flex" justify="space-around" align="middle" :gutter="[16,0]">
									<a-col :span="12">
										<div class="data_name">
							     			<span><img src="@a/images/icon/ic_voltage_big.png"></span><span>最高单体电压：</span>
							     		</div>
									</a-col>
									<a-col :span="7">
										<div class="eblock-data" >
							     			{{maxV}}
							     		</div>
									</a-col>
								</a-row>	
								<a-row type="flex" justify="space-around" align="middle" :gutter="[16,0]">
									<a-col :span="12">
										<div class="data_name">
							     			<span style="margin-left: 32px;">最高电压位置：</span>
							     		</div>
									</a-col>
									<a-col :span="7">
										<div class="eblock-data" >
							     			{{maxVindex}}
							     		</div>
									</a-col>
								</a-row>	
								<a-row type="flex" justify="space-around" align="middle" :gutter="[16,0]">
									<a-col :span="12">
										<div class="data_name">
							     			<span style="margin-left: 32px;">最低单体电压：</span>
							     		</div>
									</a-col>
									<a-col :span="7">
										<div class="eblock-data" >
							     			{{minV}}
							     		</div>
									</a-col>
								</a-row>	
								<a-row type="flex" justify="space-around" align="middle" :gutter="[16,0]">
									<a-col :span="12">
										<div class="data_name">
							     			<span style="margin-left: 32px;">最低电压位置：</span>
							     		</div>
									</a-col>
									<a-col :span="7">
										<div class="eblock-data" >
							     			{{minVindex}}
							     		</div>
									</a-col>
								</a-row>	
								<a-row type="flex" justify="space-around" align="middle" :gutter="[16,0]">
									<a-col :span="12">
										<div class="data_name">
							     			<span style="margin-left: 32px;">最大压差：</span>
							     		</div>
									</a-col>
									<a-col :span="7">
										<div class="eblock-data" >
							     			{{V_Difference}}
							     		</div>
									</a-col>
								</a-row>	
							</div>
						</div>
						<div class="eblock_page eblock_page_sub">
							<div class="eblock_title">
								温度统计
							</div>
							<div class="eblock_body eblock_body_sub">
								<a-row type="flex" justify="space-around" align="middle" :gutter="[16,0]">
									<a-col :span="12">
										<div class="data_name">
							     			<span><img src="@a/images/icon/ic_temperature.png"></span><span>最高单体温度：</span>
							     		</div>
									</a-col>
									<a-col :span="7">
										<div class="eblock-data" >
							     			{{maxT}}
							     		</div>
									</a-col>
								</a-row>	
								<a-row type="flex" justify="space-around" align="middle" :gutter="[16,0]">
									<a-col :span="12">
										<div class="data_name">
							     			<span style="margin-left: 32px;">最高温度位置：</span>
							     		</div>
									</a-col>
									<a-col :span="7">
										<div class="eblock-data" >
							     			{{maxTindex}}
							     		</div>
									</a-col>
								</a-row>	
								<a-row type="flex" justify="space-around" align="middle" :gutter="[16,0]">
									<a-col :span="12">
										<div class="data_name">
							     			<span style="margin-left: 32px;">最低单体温度：</span>
							     		</div>
									</a-col>
									<a-col :span="7">
										<div class="eblock-data" >
							     			{{minT}}
							     		</div>
									</a-col>
								</a-row>	
								<a-row type="flex" justify="space-around" align="middle" :gutter="[16,0]">
									<a-col :span="12">
										<div class="data_name">
							     			<span style="margin-left: 32px;">最低温度位置：</span>
							     		</div>
									</a-col>
									<a-col :span="7">
										<div class="eblock-data" >
							     			{{minTindex}}
							     		</div>
									</a-col>
								</a-row>	
								<a-row type="flex" justify="space-around" align="middle" :gutter="[16,0]">
									<a-col :span="12">
										<div class="data_name">
							     			<span style="margin-left: 32px;">最大温差：</span>
							     		</div>
									</a-col>
									<a-col :span="7">
										<div class="eblock-data" >
							     			{{T_Difference}}
							     		</div>
									</a-col>
								</a-row>	
							</div>
						</div>
						<div class="eblock_page eblock_page_sub">
							<div class="eblock_title">
								内阻统计
							</div>
							<div class="eblock_body eblock_body_sub">
								<a-row type="flex" justify="space-around" align="middle" :gutter="[16,0]">
									<a-col :span="12">
										<div class="data_name">
							     			<span><img src="@a/images/icon/ic_resitance.png"></span><span>最高单体内阻：</span>
							     		</div>
									</a-col>
									<a-col :span="7">
										<div class="eblock-data" >
							     			{{maxR}}
							     		</div>
									</a-col>
								</a-row>	
								<a-row type="flex" justify="space-around" align="middle" :gutter="[16,0]">
									<a-col :span="12">
										<div class="data_name">
							     			<span style="margin-left: 32px;">最高内阻位置：</span>
							     		</div>
									</a-col>
									<a-col :span="7">
										<div class="eblock-data" >
							     			{{maxRindex}}
							     		</div>
									</a-col>
								</a-row>	
								<a-row type="flex" justify="space-around" align="middle" :gutter="[16,0]">
									<a-col :span="12">
										<div class="data_name">
							     			<span style="margin-left: 32px;">最低单体内阻：</span>
							     		</div>
									</a-col>
									<a-col :span="7">
										<div class="eblock-data" >
							     			{{maxR}}
							     		</div>
									</a-col>
								</a-row>	
								<a-row type="flex" justify="space-around" align="middle" :gutter="[16,0]">
									<a-col :span="12">
										<div class="data_name">
							     			<span style="margin-left: 32px;">最低内阻位置：</span>
							     		</div>
									</a-col>
									<a-col :span="7">
										<div class="eblock-data" >
							     			{{minRindex}}
							     		</div>
									</a-col>
								</a-row>	
								<a-row type="flex" justify="space-around" align="middle" :gutter="[16,0]">
									<a-col :span="12">
										<div class="data_name">
							     			<span style="margin-left: 32px;">最大内阻差：</span>
							     		</div>
									</a-col>
									<a-col :span="7">
										<div class="eblock-data" >
							     			{{R_Difference}}
							     		</div>
									</a-col>
								</a-row>	
							</div>
						</div>
					</a-col>	
					<a-col :span="20">
						<div>
							<div id="voltage-chart" class="eblock_info_chart">
							
							</div>
							<div id="temperature-chart" class="eblock_info_chart">
								
							</div>
							<div id="internal-resistance-chart" class="eblock_info_chart">
								
							</div>
						</div>
					</a-col>	
				</a-row>
			</div>
		</div>
		<div class="eblock_page">
			<div class="eblock_title">
				PCS运行数据
			</div>
			<div class="eblock_body">
				<a-row type="flex" justify="space-around" align="middle" :gutter="[0,0]">
					<a-col :span="6">
				       <div class="eblock_data_item" id="power-chart"  style="background: #23232D; height: 430px; padding-top: 14px;">
					       	<a-col :span="3">
					       		<img src="@a/images/icon/ic_voltage_big.png">
					       	</a-col>
				       		<a-col :span="21">
					       		<div style="color: #E2E2E2; font-size: 16px; font-weight: 500; margin-bottom: 10px;">
					       			状态
					       		</div>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>PCS运行模式：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{operatingMode}}
						     		</div>
					       		</a-row>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>变流器效率：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{converterEfficiency}}
						     		</div>
					       		</a-row>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>对地绝缘阻抗：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{resistanceToGroundInsulation}}
						     		</div>
					       		</a-row>
					     		<a-row>
					     			<div style="float: left;">
					     				<div class="psc_data_name" style="padding-left: 0 !important;">
							     			<span>接触器状态：</span>
							     		</div>
					     			</div>
					     			<div style="float: left; width: calc(100%-105px); padding: 5px 0;">
					     				<div style="margin-bottom: 8px; float: left;">
					     					<div style="float: left; width: 90px; font-size: 14px; color: #E2E2E2;">
								     			直流：
								     		</div>
								     		<div style="float: left; width: calc(100%-90px);">
								     			<div class="status" id="DC_negativeCircuitBreakerStatus" style="margin: 3px 0 !important;"></div>
								     		</div>
					     				</div>
					     				<div>
					     					<div style="float: left; width: 90px; font-size: 14px; color: #E2E2E2;">
								     			交流：
								     		</div>
								     		<div style="float: left; width: calc(100%-90px);">
								     			<div class="status" id="AC_circuitBreakerStatus" style="margin: 3px 0 !important;"></div>
								     		</div>
					     				</div>
					     			</div>
					       		</a-row>
					       		<a-row>
					     			<div style="float: left;">
					     				<div class="psc_data_name" style="padding-left: 0 !important;">
							     			<span>风扇状态：</span>
							     		</div>
					     			</div>
					     			<div style="float: left; width: calc(100%-105px); padding: 5px 0;">
					     				<div style="margin-bottom: 8px; float: left;">
					     					<div style="float: left; width: 90px; font-size: 14px; color: #E2E2E2;">
								     			AC风扇: 
								     		</div>
								     		<div style="float: left; width: calc(100%-90px);">
								     			<div class="status" id="pcs_AC_fanStatus" style="margin: 3px 0 !important;"></div>
								     		</div>
					     				</div>
					     				<div>
					     					<div style="float: left; width: 90px; font-size: 14px; color: #E2E2E2; margin-bottom: 8px;">
								     			IGBT-A风扇: 
								     		</div>
								     		<div style="float: left; width: calc(100%-90px);">
								     			<div class="status" id="IGBT_A_fanStatus" style="margin: 3px 0 !important;"></div>
								     		</div>
					     				</div>
					     				<div>
					     					<div style="float: left; width: 90px; font-size: 14px; color: #E2E2E2; margin-bottom: 8px;">
								     			IGBT-B风扇: 
								     		</div>
								     		<div style="float: left; width: calc(100%-90px);">
								     			<div class="status" id="IGBT_B_fanStatus" style="margin: 3px 0 !important;"></div>
								     		</div>
					     				</div>
					     				<div>
					     					<div style="float: left; width: 90px; font-size: 14px; color: #E2E2E2; margin-bottom: 8px;">
								     			IGBT-C风扇: 
								     		</div>
								     		<div style="float: left; width: calc(100%-90px);">
								     			<div class="status" id="IGBT_C_fanStatus" style="margin: 3px 0 !important;"></div>
								     		</div>
					     				</div>
					     			</div>
					       		</a-row>
					       	</a-col>
					  	</div>
			      	</a-col>
			      <a-col :span="6">
			        	<div class="eblock_data_item" id="power-chart"  style="background: #23232D; height: 226px; margin-bottom: 24px; padding-top: 14px;">
					       	<a-col :span="3">
					       		<img src="@a/images/icon/ic_voltage_big.png">
					       	</a-col>
				       		<a-col :span="21">
					       		<div style="color: #E2E2E2; font-size: 16px; font-weight: 500; margin-bottom: 10px;">
					       			交流功率
					       		</div>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>有功功率：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{activePower}}
						     		</div>
					       		</a-row>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>无功功率：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{reactivePower}}
						     		</div>
					       		</a-row>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>视在功率：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{inspectingPower}}
						     		</div>
					       		</a-row>
					     		<a-row>
					     			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>平均功率：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{averagePower}}
						     		</div>
					       		</a-row>
					       		<a-row>
					     			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>功率因数：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{powerFactor}}
						     		</div>
					       		</a-row>
					       	</a-col>
					  	</div>
					  	<div class="eblock_data_item" id="power-chart"  style="background: #23232D; height: 166px; padding-top: 14px;">
					       	<a-col :span="3">
					       		<img src="@a/images/icon/ic_voltage_big.png">
					       	</a-col>
				       		<a-col :span="21">
					       		<div style="color: #E2E2E2; font-size: 16px; font-weight: 500; margin-bottom: 10px;">
					       			直流信息
					       		</div>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>电池总压：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{totalBatteryVoltage}}
						     		</div>
					       		</a-row>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>电池电流：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{batteryCurrent}}
						     		</div>
					       		</a-row>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>直流功率：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{power_DC}}
						     		</div>
					       		</a-row>
					       	</a-col>
					  	</div>
			      	</a-col>
			      	<a-col :span="6">
			        	<div class="eblock_data_item" id="power-chart"  style="background: #23232D; height: 226px; margin-bottom: 24px; padding-top: 14px;">
					       	<a-col :span="3">
					       		<img src="@a/images/icon/ic_voltage_big.png">
					       	</a-col>
				       		<a-col :span="21">
					       		<div style="color: #E2E2E2; font-size: 16px; font-weight: 500; margin-bottom: 10px;">
					       			温度
					       		</div>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>环境温度：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{pcsEvnTemperature}}
						     		</div>
					       		</a-row>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>机箱内温度：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{pcsInnerTemperature}}
						     		</div>
					       		</a-row>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>IGBT-A温度：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{IGBTTemperature_A}}
						     		</div>
					       		</a-row>
					     		<a-row>
					     			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>IGBT-B温度：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{IGBTTemperature_B}}
						     		</div>
					       		</a-row>
					       		<a-row>
					     			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>IGBT-C温度：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{IGBTTemperature_C}}
						     		</div>
					       		</a-row>
					       	</a-col>
					  	</div>
					  	<div class="eblock_data_item" id="power-chart"  style="background: #23232D; height: 166px; padding-top: 14px;">
					       	<a-col :span="3">
					       		<img src="@a/images/icon/ic_voltage_big.png">
					       	</a-col>
				       		<a-col :span="21">
					       		<div style="color: #E2E2E2; font-size: 16px; font-weight: 500; margin-bottom: 10px;">
					       			时间
					       		</div>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>运行时间：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{operationHours}}
						     		</div>
					       		</a-row>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>启机时间：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{startingTime}}
						     		</div>
					       		</a-row>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>停机时间：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{stopingTime}}
						     		</div>
					       		</a-row>
					       	</a-col>
					  	</div>
			      	</a-col>
			      	<a-col :span="5">
			        	<div class="eblock_data_item" id="power-chart"  style="background: #23232D; height: 430px; padding-top: 14px;">
					       	<a-col :span="3">
					       		<img src="@a/images/icon/ic_voltage_big.png">
					       	</a-col>
				       		<a-col :span="21">
					       		<div style="color: #E2E2E2; font-size: 16px; font-weight: 500; margin-bottom: 10px;">
					       			电网信息
					       		</div>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>AB线电压：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{lineVoltage_AB}}
						     		</div>
					       		</a-row>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>BC线电压：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{lineVoltage_BC}}
						     		</div>
					       		</a-row>
					       		<a-row>
					       			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>AC线电压：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{lineVoltage_CA}}
						     		</div>
					       		</a-row>
					     		<a-row>
					     			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>A相电流：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{phaseCurrent_A}}
						     		</div>
					       		</a-row>
					       		<a-row>
					     			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>B相电流：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{phaseCurrent_B}}
						     		</div>
					       		</a-row>
					       		<a-row>
					     			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>C相电流：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{phaseCurrent_C}}
						     		</div>
					       		</a-row>
					       		<a-row>
					     			<div class="psc_data_name" style="padding-left: 0 !important;">
						     			<span>电网频率：</span>
						     		</div>
									<div class="eblock-data" style="width: calc(100%-105px);">
						     			{{gridFrequency}}
						     		</div>
					       		</a-row>
					       	</a-col>
					  	</div>
			      	</a-col>
				</a-row>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import $ from 'jquery';
	export default {
		data() {
			return {
				smoke: -1,
				upperFlood: -1,
				downFlood: -1,
				accessControlLeft: -1,
				accessControlRight: -1,
				aerosolInputDetectionStatus: -1,
				airConditioningCoolingStatus: -1,
				airConditioningHeatingStatus: -1,
				innerAirConditionerFanStatus: -1,
				outsideAirConditionerFanStatus: -1,
				airConditionerSwitchStatus: -1,
				outterTemperature: '未知',
				innerVO2Concentration: '未知',
				outterHumidity: '未知',
				eblockstate: "未知",
				timer_eblock_data: null,
				timer_cell_data: null,
				powerChart: null,
				powerOption: null,
				maxPower: 150,
				powerDC: 0,
				soc: 0,
				flag: false,
				
				chart_x_cell: [],
				chart_x_temperatureProbe: [],
				voltage: [],
				temperature: [],
				internalResistance: [],
				cellCount: 224,
				temperatureProbeCount: 224,
				voltageChart: null,
				voltageOption: null,
				irChart: null,
				irOption: null,
				temperatureChart: null,
				temperatureOption: null,
				maxT: '未知',
				minT: '未知',
				T_Difference: '未知',
				minV: '未知',
				maxV: '未知',
				V_Difference: '未知',
				minR: '未知',
				maxR: '未知',
				R_Difference: '未知',
				minVindex: '未知',
				maxVindex: '未知',
				minTindex: '未知',
				maxTindex: '未知',
				minRindex: '未知',
				maxRindex: '未知',
				irRecordTime: '',
				tAndVRecordTime: '',
				infoRecordTime: '',
				
				operatingMode: '未知',
				converterEfficiency: '未知',
				AC_circuitBreakerStatus: -1,
				DC_negativeCircuitBreakerStatus: -1,
				pcs_AC_fanStatus: -1,
				IGBT_A_fanStatus: -1,
				IGBT_B_fanStatus: -1,
				IGBT_C_fanStatus: -1,
				resistanceToGroundInsulation: '未知',
				operationHours: '未知',
				startingTime: '未知',
				stopingTime: '未知',
				pcsEvnTemperature: '未知',
				pcsInnerTemperature: '未知',
				IGBTTemperature_A: '未知',
				IGBTTemperature_B: '未知',
				IGBTTemperature_C: '未知',
				lineVoltage_AB: '未知',
				lineVoltage_BC: '未知',
				lineVoltage_CA: '未知',
				phaseCurrent_A: '未知',
				phaseCurrent_B: '未知',
				phaseCurrent_C: '未知',
				gridFrequency: '未知',
				inspectingPower: '未知',
				reactivePower: '未知',
				activePower: '未知',
				powerFactor: '未知',
				averagePower: '未知',
				totalBatteryVoltage: '未知',
				batteryCurrent: '未知',
				power_DC: '未知',
				params: {
					'id': -1,
					'startIndex': 0,
					'typeId': 1,
					'length': 1000000
				},
			}
		},
		destroyed() {
			clearInterval(this.timer_eblock_data);
			clearInterval(this.timer_cell_data);
		},
		mounted() {
			var this_ = this;
			this.initPowerChart();
			this.initCellChart();
			this.geteblockData();
			this_.timer_eblock_data = window.setInterval(function() {
				this_.geteblockData();
			}, 3000);
			this_.getCellData();
			this_.getPSCData();
			this_.timer_cell_data = window.setInterval(function() {
				this_.getCellData();
				this_.getPSCData();
			}, 10000);
		},
		methods: {
			initPowerChart() {
				var this_ = this;
				this_.powerOption = {
					series: [{
						name: '实时功率',
						type: 'gauge',
						radius: '100%',
						splitLine: {
							length: 15,
						},
						title: {
							color: '#B7B8BC',
							fontSize: 13,
						},
						startAngle: 230, //开始角度
						endAngle: -50, //结束角度
						min: (this_.maxPower * -1), // 最小的数据值,默认 0 。映射到 minAngle。
						max: this_.maxPower, // 最大的数据值,默认 100 。映射到 maxAngle。
						axisLine: {
							show: true,
							lineStyle: { // 属性lineStyle控制线条样式
								color: [
									[0.1, "#F4664A"],
									[0.4, "#30BF78"],
									[0.6, "#30BF78"],
									[0.9, "#30BF78"],
									[1, "#F4664A"]
								],
								width: 10
							}
						},
						pointer: {
							length: '60%',
							width: 5
						},
						detail: {
							fontSize: '100%',
							color: '#000',
							formatter: '{value}kW',
							color: '#E2E2E2',
							offsetCenter: [0, '30%']
						},
						data: [{
							value: this_.powerDC,
							name: '实时功率'
						}]
					}]
				};
				this_.powerChart = this_.$echarts.init(document.getElementById("power-chart"));
				this_.powerChart.setOption(this_.powerOption);
				setTimeout(() => {
					window.onresize = function() {
						this_.powerChart.resize();
					}
				}, 50);
			},
			initCellChart() {
				var this_ = this;
				this_.temperatureOption = {
					title: {
						textStyle: {
							color: '#E2E2E2',
							fontFamily: "微软雅黑",
							fontSize: 16,
						},
						text: '单体电池温度实时曲线' + this_.tAndVRecordTime,
						subtextStyle: {
							color: '#B7B8BC',
							fontFamily: "微软雅黑",
						},
						x: 'center'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'cross' // 默认为直线，可选为：'line' | 'shadow'
						},
						formatter: function(params) {
							var relVal = '第' + params[0].name + '节电池' + "<br/>温度（℃）：" + params[0].value;
							return relVal;
						}
					},
					grid: {
						left: 50,
						right: 50,
						top: '50',
						height: '60%'
					},
					xAxis: {
						nameTextStyle: {
							color: '#7C868D',
							fontFamily: "微软雅黑",
						},
						axisLine: {
							lineStyle: {
								color: '#7C868D',
								width: 1, //这里是为了突出显示加上的
							}
						},
						name: '序号',
						type: 'category',
						data: this_.chart_x_temperatureProbe,
						axisTick: {
							alignWithLabel: true
						}
					},
					yAxis: {
						nameTextStyle: {
							color: '#7C868D',
							fontFamily: "微软雅黑",
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#7C868D',
								width: 1, //这里是为了突出显示加上的
							}
						},
						splitLine: {
							lineStyle: {
								color: '#7C868D',
								width: 1, //这里是为了突出显示加上的
							}
						},
						name: '温度(℃)',
						type: 'value',
					},
					series: [{
						name: '温度',
						type: 'line',
						itemStyle: {
							color: '#49AA19'
						},
						areaStyle: {
							color: new this_.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 0,
								color: 'rgba(73, 170, 25, 0.3)'
							}, {
								offset: 0.5,
								color: 'rgba(73, 170, 25, 0.1)',
							}, {
								offset: 1,
								color: 'rgba(73, 170, 25, 0)',
							}])
						},
						data: this_.temperature
					}]
				};
				this_.voltageOption = {
					title: {
						textStyle: {
							color: '#E2E2E2',
							fontFamily: "微软雅黑",
							fontSize: 16,
						},
						text: '单体电池电压实时曲线' + this_.tAndVRecordTime,
						subtextStyle: {
							color: '#B7B8BC',
							fontFamily: "微软雅黑",
						},
						x: 'center'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross'
						},
						formatter: function(params) {
							var relVal = '第' + params[0].name + '节电池' + "<br/>电压（V）：" + params[0].value;
							return relVal;
						}
					},
					grid: {
						left: 50,
						right: 50,
						top: '50',
						height: '60%'
					},
					xAxis: {
						nameTextStyle: {
							color: '#7C868D',
							fontFamily: "微软雅黑",
						},
						axisLine: {
							onZero: true,
							lineStyle: {
								color: '#7C868D',
								width: 1, //这里是为了突出显示加上的
							}
						},
						name: '序号',
						type: 'category',
						boundaryGap: false,
						data: this_.chart_x_cell
					},

					yAxis: [{
						nameTextStyle: {
							color: '#7C868D',
							fontFamily: "微软雅黑",
						},
						axisLine: {
							lineStyle: {
								color: '#7C868D',
								width: 1, //这里是为了突出显示加上的
							}
						},
						splitLine: {
							lineStyle: {
								color: '#7C868D',
								width: 1, //这里是为了突出显示加上的
							}
						},
						max: 3.8,
						min: 2.5,
						name: '电压（V）',
						type: 'value',
						inverse: false
					}],
					series: {
						name: '电压（V）',
						type: 'line',
						itemStyle: {
							color: '#1890FF'
						},
						areaStyle: {
							color: new this_.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 0,
								color: 'rgba(24, 144, 225, 0.3)'
							}, {
								offset: 0.5,
								color: 'rgba(24, 144, 225, 0.1)',
							}, {
								offset: 1,
								color: 'rgba(24, 144, 225, 0)',
							}])
						},
						data: this_.voltage
					}

				};
				this_.irOption = {
					title: {
						textStyle: {
							color: '#E2E2E2',
							fontFamily: "微软雅黑",
							fontSize: 16,
						},
						text: '单体电池内阻实时曲线' + this_.irRecordTime,
						subtextStyle: {
							color: '#B7B8BC',
							fontFamily: "微软雅黑",
						},
						x: 'center'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross'
						}
					},
					grid: {
						left: 50,
						right: 50,
						top: '50',
						height: '60%'
					},
					xAxis: {
						nameTextStyle: {
							color: '#7C868D',
							fontFamily: "微软雅黑",
						},
						axisLine: {
							onZero: true,
							lineStyle: {
								color: '#7C868D',
								width: 1, //这里是为了突出显示加上的
							}
						},
						
						name: '序号',
						type: 'category',
						boundaryGap: false,
						data: this_.chart_x_cell
					},

					yAxis: [{
						nameTextStyle: {
							color: '#7C868D',
							fontFamily: "微软雅黑",
						},
						axisLine: {
							lineStyle: {
								color: '#7C868D',
								width: 1, //这里是为了突出显示加上的
							}
						},
						splitLine: {
							lineStyle: {
								color: '#7C868D',
								width: 1, //这里是为了突出显示加上的
							}
						},
						name: '内阻（0.001mΩ）',
						type: 'value',
						inverse: false
					}],
					series: {
						name: '内阻（0.001mΩ）',
						type: 'line',
						itemStyle: {
							color: '#F26813'
						},
						areaStyle: {
							color: new this_.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 0,
								color: 'rgba(242, 104, 19, 0.3)'
							}, {
								offset: 0.5,
								color: 'rgba(242, 104, 19, 0.1)',
							}, {
								offset: 1,
								color: 'rgba(242, 104, 19, 0)',
							}])
						},
						data: this_.internalResistance
					}

				};
				if(this_.temperature.length < 1) {
					this_.temperatureOption.title = {
						textStyle: {
							fontFamily: "微软雅黑",
							color: '#E2E2E2',
							fontSize: 16,
						},
						text: '单体电池温度实时曲线' + this_.tAndVRecordTime,
						subtext: '\n\r\n\r\n\r\n\r暂无数据',
						subtextStyle: {
							color: '#B7B8BC',
							fontFamily: "微软雅黑",
						},
						x: 'center'
					};
				}
				if(this_.voltage.length < 1) {
					this_.voltageOption.title = {
						textStyle: {
							fontFamily: "微软雅黑",
							color: '#E2E2E2',
							fontSize: 16,
						},
						text: '单体电池电压实时曲线' + this_.tAndVRecordTime,
						subtext: '\n\r\n\r\n\r\n\r暂无数据',
						subtextStyle: {
							color: '#B7B8BC',
							fontFamily: "微软雅黑",
						},
						x: 'center'
					};
				}
				if(this_.internalResistance.length < 1) {
					this_.irOption.title = {
						textStyle: {
							fontFamily: "微软雅黑",
							color: '#E2E2E2',
							fontSize: 16,
						},
						text: '单体电池内阻实时曲线' + this_.irRecordTime,
						subtext: '\n\r\n\r\n\r\n\r暂无数据',
						subtextStyle: {
							color: '#B7B8BC',
							fontFamily: "微软雅黑",
						},
						x: 'center'
					};
				}
				this_.voltageChart = this_.$echarts.init(document.getElementById("voltage-chart"));
				this_.voltageChart.setOption(this_.voltageOption);
				this_.temperatureChart = this_.$echarts.init(document.getElementById("temperature-chart"));
				this_.temperatureChart.setOption(this_.temperatureOption);
				this_.irChart = this_.$echarts.init(document.getElementById("internal-resistance-chart"));
				this_.irChart.setOption(this_.irOption);
				setTimeout(() => {
					window.onresize = function() {
						this_.voltageChart.resize();
						this_.temperatureChart.resize();
						this_.irChart.resize();
					}
				}, 50);
			},
			geteblockData() { //获取侧边栏信息
				var this_ = this;
				this_.params.id = localStorage.getItem('eblockId');
				this_.params.typeId = 1;
				axios({
					method: 'post',
					url: '/emind/config/data',
					data: this_.params
				}).then(function(d) { //
					if(!d){
						this_.$message.error('登录超时，请重新登录', 2, function() {});
						return;
					}
					if(d.data.code === '0') {
						var data = d.data.data.data;
						this_.powerDC = this_.getRealValue(data.power, "", 0, 10);
						this_.soc = this_.getRealValue(data.soc, "%", 0, 10);
						var cnt = document.getElementById("count");
			        	var water = document.getElementById("water");
						if(!this_.flag){
					        var percent = parseInt(cnt.innerText);
					        var stepSize = data.soc  > percent * 10 ? 1 : -1;
					        var interval;
					        interval = setInterval(function () {
					        	percent = percent + stepSize * 1.3;
					            cnt.innerHTML = percent.toFixed(1);
					            water.style.transform = 'translate(0' + ',' + (100 - percent).toFixed(1) + '%)';
					            if (Math.abs(data.soc - percent * 10) < 14) {
					            	cnt.innerHTML = data.soc / 10;
					            	water.style.transform = 'translate(0' + ',' + (100 - percent).toFixed(1) + '%)';
					                clearInterval(interval);
					            }
					        }, 60);
					        this_.flag = true;
						} else {
							var percent = data.soc / 10;
						 	cnt.innerHTML = percent.toFixed(1);
				            water.style.transform = 'translate(0' + ',' + (100 - percent) + '%)';
						}
						this_.infoRecordTime = data.infoRecordTime ==null ? '' : '(' + new Date(data.infoRecordTime * 1000).Format("yyyy-MM-dd hh:mm:ss") + ')';
						var dom = $("#status");
						if(data.eblockstate == null) {
							this_.eblockstate = "离线";
							dom.css("color", "#F4664A");
						} else if(data.eblockstate == 1) {
							this_.eblockstate = "停机";
							dom.css("color", "#FAAD14");
						} else if(data.eblockstate == 2) {
							dom.css("color", "#177DDC");
							this_.eblockstate = "待机";
						} else if(data.eblockstate == 3) {
							dom.css("color", "#49AA19");
							this_.eblockstate = "充电";
						} else if(data.eblockstate == 4) {
							dom.css("color", "#dd0000");
							this_.eblockstate = "放电";
						} else if(data.eblockstate == 5) {
							this_.eblockstate = "降额";
						} else if(data.eblockstate == 6) {
							if(this_.powerDC > 0){
								dom.css("color", "#dd0000");
								this_.eblockstate = "放电";
							} else if(this_.powerDC == 0){
								dom.css("color", "#177DDC");
								this_.eblockstate = "待机";
							} else if(this_.powerDC < 0){
								dom.css("color", "#49AA19");
								this_.eblockstate = "充电";
							} else {
								this_.eblockstate = "离线";
							}
						} else if(data.eblockstate == 7) {
							this_.eblockstate = "故障";
						} else {
							dom.css("color", "#F4664A");
							this_.eblockstate = "离线";
						}
//						this_.innerH2Concentration = this_.getRealValue(data.innerH2Concentration, "ppm", "未知", 1);
//						this_.innerCO2Concentration = this_.getRealValue(data.innerCO2Concentration, "ppm", "未知", 1);
						this_.innerVO2Concentration = this_.getRealValue(data.innerVO2Concentration, "ppm", "未知", 1);
						this_.outterTemperature = this_.getRealValue(data.outterTemperature, "℃", "未知", 10);
						this_.outterHumidity = this_.getRealValue(data.outterHumidity, "%", "未知", 10);
						this_.heighTemperatureCabinet = this_.getRealValue(data.heighTemperatureCabinet, "℃", "未知", 10);
						this_.lowTemperatureCabinet = this_.getRealValue(data.lowTemperatureCabinet, "℃", "未知", 10);
						this_.setStatus("#smoke", this_.getRealValue(data.smoke, "", -1, 1));
						this_.setStatus("#upperFlood", this_.getRealValue(data.upperFlood, "", -1, 1));
						this_.setStatus("#downFlood", this_.getRealValue(data.downFlood, "", -1, 1));
						this_.setStatus("#accessControlLeft", this_.getRealValue(data.accessControlLeft, "", -1, 1));
						this_.setStatus("#accessControlRight", this_.getRealValue(data.accessControlRight, "", -1, 1));
						this_.setStatus("#airConditioningCoolingStatus", this_.getRealValue(data.airConditioningCoolingStatus, "", -1, 1));
						this_.setStatus("#airConditioningHeatingStatus", this_.getRealValue(data.airConditioningHeatingStatus, "", -1, 1));
						this_.setStatus("#innerAirConditionerFanStatus", this_.getRealValue(data.innerAirConditionerFanStatus, "", -1, 1));
						this_.setStatus("#outsideAirConditionerFanStatus", this_.getRealValue(data.outsideAirConditionerFanStatus, "", -1, 1));
						this_.setStatus("#airConditionerSwitchStatus", this_.getRealValue(data.airConditionerSwitchStatus, "", -1, 1));
						this_.setStatus("#aerosolInputDetectionStatus", this_.getRealValue(data.aerosolInputDetectionStatus, "", -1, 1));
						this_.maxPower = data.maxPower;
						this_.refulshData(1);
					} else {
						this_.$message.error('请求出错', 2, function() {});
					}
				}).catch(function(error) {
					if(error.message != '') {
						this_.$message.error(error.message, 2, function() {});
						return;
					}
				});
			},
			setStatus(id, value){
				if(id == '#airConditionerSwitchStatus'){
					if(value == 0){
						value = "1";
					} else if(value == 1){
						value = "0";
					}
				}
				var dom = $(id);
				if(value === "1"){
					dom.css("background", "#F26813");
				} else if(value === "0"){
					dom.css("background", "#49AA19");
				} else {
					dom.css("background", "#7C868D");
				}
			},
			getRealValue(value, unit, isNullValue, mudel){
				if(value == null) {
					return isNullValue;
				} else {
					return value / mudel + unit;
				}
			},
			getCellData() { //获取侧边栏信息
				var this_ = this;
				this_.params.id = localStorage.getItem('eblockId');
				this_.params.typeId = 2;
				axios({
					method: 'post',
					url: '/emind/config/data',
					data: this_.params
				}).then(function(d) { //
					if(!d){
						this_.$message.error('登录超时，请重新登录', 2, function() {});
						return;
					}
					if(d.data.code === '0') {
						var data = d.data.data.data || {};
						this_.tAndVRecordTime = data.tAndVRecordTime ==null ? '' : '(' + new Date(data.tAndVRecordTime * 1000).Format("yyyy-MM-dd hh:mm:ss") + ')';
						this_.irRecordTime = data.irRecordTime ==null ? '' : '(' + new Date(data.irRecordTime * 1000).Format("yyyy-MM-dd hh:mm:ss") + ')';
						this_.cellCount = data.cellCount;
						this_.temperatureProbeCount = data.temperatureProbeCount;
						if(data.boardTemperatureCount != null){
							this_.temperatureProbeCount = this_.temperatureProbeCount - data.boardTemperatureCount;
						}

						this_.chart_x_cell = [];
						this_.chart_x_temperatureProbe = [];
						this_.voltage = [];
						this_.temperature = [];
						this_.internalResistance = [];
						if(data.maxT == null) {
							this_.showUnit_V = false;
							this_.maxT = '未知';
							this_.minT = '未知';
							this_.T_Difference = '未知';
							this_.minV = '未知';
							this_.maxV = '未知';
							this_.V_Difference = '未知';
							this_.minVindex = '未知';
							this_.maxVindex = '未知';
							this_.minTindex = '未知';
							this_.maxTindex = '未知';
							for(var i = 1; i <= this_.cellCount; i++) {
								this_.chart_x_cell.push(i);
							}
							for(var i = 1; i <= this_.temperatureProbeCount; i++) {
								this_.chart_x_temperatureProbe.push(i);
							}
						} else {
							this_.showUnit_V = true;
							this_.maxT = -10000;
							this_.minT = 10000;
							this_.maxT = data.maxT + ' ℃';
							this_.minT = data.minT + ' ℃';
							this_.T_Difference = data.T_Difference + ' ℃';
							this_.minV = data.minV.toFixed(3) + ' V';
							this_.maxV = data.maxV.toFixed(3) + ' V';
							this_.V_Difference = data.V_Difference.toFixed(3) + ' V';
							this_.minVindex = data.minVindex;
							this_.maxVindex = data.maxVindex;
							this_.minTindex = data.minTindex;
							this_.maxTindex = data.maxTindex;
							var key = '';
							for(var i = 1; i <= this_.cellCount; i++) {
								this_.chart_x_cell.push(i);
								key = 'v' + i;
								this_.voltage.push(data[key].toFixed(3));
							}
							for(var i = 1; i <= this_.temperatureProbeCount; i++) {
								this_.chart_x_temperatureProbe.push(i);
								key = 't' + i;
								this_.temperature.push(data[key]);
							}
						}
						if(data.maxR == null) {
							this_.showUnit_R = false;
							this_.minR = '未知';
							this_.maxR = '未知';
							this_.R_Difference = '未知';
							this_.minRindex = '未知';
							this_.maxRindex = '未知';
						} else {
							this_.showUnit_R = true;
							this_.minR = data.minR / 1000;
							this_.maxR = data.maxR / 1000;
							if(214748364 == data.minR){
								this_.minR = 0;
							}
							this_.R_Difference = (this_.maxR - this_.minR).toFixed(3) + 'mΩ';
							this_.minR = this_.minR + 'mΩ';
							this_.maxR = this_.maxR + 'mΩ';
							this_.minRindex = data.minRindex;
							this_.maxRindex = data.maxRindex;
							for(var i = 1; i <= this_.temperatureProbeCount; i++) {
								key = 'r' + i;
								this_.internalResistance.push(data[key]);
							}
						}
						this_.refulshData(2);
					} else {
						this_.$message.error('请求出错', 2, function() {});
					}
				}).catch(function(error) {
					if(error.message != '') {
						this_.$message.error(error.message, 2, function() {});
						return;
					}
				});
			},
			getPSCData() { //获取侧边栏信息
				var this_ = this;
				this_.params.id = localStorage.getItem('eblockId');
				this_.params.typeId = 3;
				axios({
					method: 'post',
					url: '/emind/config/data',
					data: this_.params
				}).then(function(d) { //
					if(!d){
						this_.$message.error('登录超时，请重新登录', 2, function() {});
						return;
					}
					if(d.data.code === '0') {
						var data = d.data.data.data;
						this_.pcsRecordTime = data.pcsRecordTime ==null ? '' : '(' + new Date(data.pcsRecordTime * 1000).Format("yyyy-MM-dd hh:mm:ss") + ')';
						if(data.operatingMode == null) {
							this_.operatingMode = "未知";
						} else if(data.operatingMode == 0) {
							this_.operatingMode = "独立逆变";
						} else if(data.operatingMode == 1) {
							this_.operatingMode = "MPPT";
						} else if(data.operatingMode == 2) {
							this_.operatingMode = "恒功率";
						} else if(data.operatingMode == 3) {
							this_.operatingMode = "恒压";
						} else if(data.operatingMode == 4) {
							this_.operatingMode = "恒流";
						} else if(data.operatingMode == 5) {
							this_.operatingMode = "SVG";
						} else if(data.operatingMode == 6) {
							this_.operatingMode = "交流电压源";
						} else if(data.operatingMode == 7) {
							this_.operatingMode = "整流模式（老化）";
						} else if(data.operatingMode == 8) {
							this_.operatingMode = "二级恒流";
						} else if(data.operatingMode == 9) {
							this_.operatingMode = "独立Boost";
						} else {
							this_.operatingMode = "未知";
						}
						this_.setStatus("#AC_circuitBreakerStatus", this_.getRealValue(data.AC_circuitBreakerStatus, "", -1, 1));
						this_.setStatus("#DC_negativeCircuitBreakerStatus", this_.getRealValue(data.DC_negativeCircuitBreakerStatus, "", -1, 1));
						this_.setStatus("#pcs_AC_fanStatus", this_.getRealValue(data.pcs_AC_fanStatus, "", -1, 1));
						this_.setStatus("#IGBT_A_fanStatus", this_.getRealValue(data.IGBT_A_fanStatus, "", -1, 1));
						this_.setStatus("#IGBT_B_fanStatus", this_.getRealValue(data.IGBT_B_fanStatus, "", -1, 1));
						this_.setStatus("#IGBT_C_fanStatus", this_.getRealValue(data.IGBT_C_fanStatus, "", -1, 1));
						
						this_.converterEfficiency = this_.getRealValue(data.converterEfficiency, "%", "未知", 1);
						this_.resistanceToGroundInsulation = this_.getRealValue(data.resistanceToGroundInsulation, "kΩ", "未知", 10);
						this_.operationHours = this_.getRealValue(data.operationHours, "min", "未知", 1);
						this_.startingTime = this_.getRealValue(data.startingTime, "min", "未知", 1);
						this_.stopingTime = this_.getRealValue(data.stopingTime, "min", "未知", 1);
						this_.pcsEvnTemperature = this_.getRealValue(data.pcsEvnTemperature, "℃", "未知", 10);
						this_.pcsInnerTemperature = this_.getRealValue(data.pcsInnerTemperature, "℃", "未知", 10);
						this_.IGBTTemperature_A = this_.getRealValue(data.IGBTTemperature_A, "℃", "未知", 10);
						this_.IGBTTemperature_B = this_.getRealValue(data.IGBTTemperature_B, "℃", "未知", 10);
						this_.IGBTTemperature_C = this_.getRealValue(data.IGBTTemperature_C, "℃", "未知", 10);
						this_.lineVoltage_AB = this_.getRealValue(data.lineVoltage_AB, "V", "未知", 10);
						this_.lineVoltage_BC = this_.getRealValue(data.lineVoltage_BC, "V", "未知", 10);
						this_.lineVoltage_CA = this_.getRealValue(data.lineVoltage_CA, "V", "未知", 10);
						this_.phaseCurrent_A = this_.getRealValue(data.phaseCurrent_A, "A", "未知", 10);
						this_.phaseCurrent_B = this_.getRealValue(data.phaseCurrent_B, "A", "未知", 10);
						this_.phaseCurrent_C = this_.getRealValue(data.phaseCurrent_C, "A", "未知", 10);
						this_.gridFrequency = this_.getRealValue(data.gridFrequency, "Hz", "未知", 100);
						this_.inspectingPower = this_.getRealValue(data.inspectingPower, "kVA", "未知", 10);
						this_.reactivePower = this_.getRealValue(data.reactivePower, "kvar", "未知", 10);
						this_.activePower = this_.getRealValue(data.activePower, "kW", "未知", 10);
						this_.powerFactor = this_.getRealValue(data.powerFactor, "", "未知", 1000);
						this_.averagePower = this_.getRealValue(data.averagePower, "kW", "未知", 10);
						this_.totalBatteryVoltage = this_.getRealValue(data.totalBatteryVoltage, "V", "未知", 10);
						this_.batteryCurrent = this_.getRealValue(data.batteryCurrent, "A", "未知", 10);
						this_.power_DC = this_.getRealValue(data.power_DC, "kW", "未知", 10);
					} else {
						this_.$message.error('请求出错', 2, function() {});
					}
				}).catch(function(error) {
					if(error.message != '') {
						this_.$message.error(error.message, 2, function() {});
						return;
					}
				});
			},
			refulshData(type) {
				var this_ = this;
				if(type == 1) {
					var newOp = this_.powerChart.getOption();
					newOp.series[0].min = this_.maxPower * -1;
					newOp.series[0].max = this_.maxPower;
					newOp.series[0].data[0].value = this_.powerDC;
					this_.powerChart.setOption(newOp);
				} else if(type == 2) {
					var newOp = this_.temperatureChart.getOption();
					newOp.series[0].data = this_.temperature;
					newOp.xAxis[0].data = this_.chart_x_temperatureProbe;
					if(this_.temperature.length < 1) {
						newOp.title = {
							textStyle: {
								fontFamily: "微软雅黑",
								color: '#E2E2E2',
								fontSize: 16,
							},
							text: '单体电池温度实时曲线' + this_.tAndVRecordTime,
							subtext: '\n\r\n\r\n\r\n\r暂无数据',
							subtextStyle: {
								color: '#B7B8BC',
								fontFamily: "微软雅黑",
							},
							x: 'center'
						};
					} else {
						newOp.title = {
							textStyle: {
								fontFamily: "微软雅黑",
								color: '#E2E2E2',
								fontSize: 16,
							},
							text: '单体电池温度实时曲线' + this_.tAndVRecordTime,
							subtext: '',
							x: 'center'
						};
					}
					this_.temperatureChart.setOption(newOp);

					newOp = this_.voltageChart.getOption();
					newOp.series[0].data = this_.voltage;
					newOp.xAxis[0].data = this_.chart_x_cell;
					if(this_.voltage.length < 1) {
						newOp.title = {
							textStyle: {
								fontFamily: "微软雅黑",
								color: '#E2E2E2',
								fontSize: 16,
							},
							text: '单体电池电压实时曲线' + this_.tAndVRecordTime,
							subtext: '\n\r\n\r\n\r\n\r暂无数据',
							subtextStyle: {
								color: '#B7B8BC',
								fontFamily: "微软雅黑",
							},
							x: 'center'
						};
					} else {
						newOp.title = {
							textStyle: {
								fontFamily: "微软雅黑",
								color: '#E2E2E2',
								fontSize: 16,
							},
							text: '单体电池电压实时曲线' + this_.tAndVRecordTime,
							subtext: '',
							x: 'center'
						};
					}
					this_.voltageChart.setOption(newOp);

					newOp = this_.irChart.getOption();
					newOp.series[0].data = this_.internalResistance;
					newOp.xAxis[0].data = this_.chart_x_cell;
					if(this_.internalResistance.length < 1) {
						newOp.title = {
							textStyle: {
								fontFamily: "微软雅黑",
								color: '#E2E2E2',
								fontSize: 16,
							},
							text: '单体电池内阻实时曲线' + this_.irRecordTime, 
							subtext: '\n\r\n\r\n\r\n\r暂无数据',
							subtextStyle: {
								color: '#B7B8BC',
								fontFamily: "微软雅黑",
							},
							x: 'center'
						};
					} else {
						newOp.title = {
							textStyle: {
								fontFamily: "微软雅黑",
								color: '#E2E2E2',
								fontSize: 16,
							},
							text: '单体电池内阻实时曲线' + this_.irRecordTime, 
							subtext: '',
							x: 'center'
						};
					}
					this_.irChart.setOption(newOp);
				}
			},
		}
	}
</script>



<style>
@import '../../assets/css/global.css';
@import "../../assets/css/antd.css";
.body.body--eblock {
  overflow-y: auto;
}
#power-chart{
	padding: 0;
	height: 200px;
	border-radius: 2px;
}
.eblock_page{
	padding: 16px;
	margin-bottom: 36px;
	background: #30303C;
	border-radius: 2px;
}
.eblock_page_sub{
	padding: 0 !important;
}
.eblock_title{
	height: 27px;
	font-family: PingFangSC-Semibold;
	font-size: 16px;
	color: #E2E2E2;
	line-height: 27px;
	font-weight: 600;
}
.eblock_body{
	padding: 16px 16px;
}
.eblock_body_sub{
	padding: 16px 0 0 0 !important;
}
.eblock_data_name{
	padding-left: 16px 0;
	float: left;
	width: 50%;
	height: 32px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #B7B8BC;
	line-height: 32px;
	font-weight: 400;
}
.eblock_data_name_state{
	font-size: 16px !important;
	color: #B7B8BC;
	font-weight: 600;
}
.eblock_data_value_state{
	font-size: 16px !important;
	font-weight: 600;
}
.eblock_data_value{
	padding-right: 16px 0;
	float: left;
	width: 50%;
	height: 32px;
	font-family: PingFangSC-Semibold;
	font-size: 14px;
	color: #E2E2E2;
	line-height: 32px;
	font-weight: 600;
}
.status{
	margin: 8px;
	width: 16px;
	height: 16px;
	border-radius: 16px;
	background: #7C868D;
}
.data_name{
	float: left;
	padding-left: 5px;
	width: 135px;
	height: 32px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #B7B8BC;
	line-height: 32px;
	font-weight: 400;
}
.eblock-data{
	text-align: center;
	float: left;
	height: 32px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #E2E2E2;
	line-height: 32px;
	font-weight: 400;
}
.eblock_info_chart{
	padding-bottom: 20px;
	width: 100%;
	height: 215px;
}
.psc_data_name{
	float: left;
	padding-left: 5px;
	width: 105px;
	height: 32px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #B7B8BC;
	line-height: 32px;
	font-weight: 400;
}
.box {
  height: 180px;
  width: 180px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #020438;
  border-radius: 100%;
  overflow: hidden;
}
.box .percent {
  	position: absolute;
  	left: 0;
  	top: 0;
  	z-index: 3;
  	width: 100%;
  	height: 100%;
  	display: flex;
  	display: -webkit-flex;
  	align-items: center;
  	justify-content: center;
  	font-family: PingFangSC-Semibold;
	font-size: 18px;
	color: #E2E2E2;
	line-height: 27px;
	font-weight: 600;
}
.box .water {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(0, 100%);
          transform: translate(0, 100%);
  background: #4D6DE3;
  transition: all .3s;
}
.box .water_wave {
  width: 200%;
  position: absolute;
  bottom: 100%;
}
.box .water_wave_back {
  right: 0;
  fill: #C7EEFF;
  -webkit-animation: wave-back 1.4s infinite linear;
          animation: wave-back 1.4s infinite linear;
}
.box .water_wave_front {
  left: 0;
  fill: #4D6DE3;
  margin-bottom: -1px;
  -webkit-animation: wave-front .7s infinite linear;
          animation: wave-front .7s infinite linear;
}
@-webkit-keyframes wave-front {
  100% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}

@keyframes wave-front {
  100% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}
@-webkit-keyframes wave-back {
  100% {
    -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0);
  }
}
@keyframes wave-back {
  100% {
    -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0);
  }
}
</style>